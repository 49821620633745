<template>
  <div>
    <div class="air__utils__heading">
      <h5>{{ type }} de almacén </h5>
    </div>
    <my-breadcrumbs :routes="routes" class="my-4"/>

    <!-- Filtro de plantas para todos los tabs-->
    <div class="row justify-content-center mt-1 mb-4 py-3 bg-white">
      <div class="col">
        <globalPlantFilter @filterValue="filterPlant = $event" />
        <p class="text-warning text-center font-size-14">
          El cambio de planta reiniciará la operacion de transacción
        </p>
      </div>
    </div>
    <!-- Filtro de plantas para todos los tabs-->
    <div v-show="filterPlant !== '' && !loading">
      <!--BOTONERA  -->
      <transactionCart :type="type" @deletedItem="deleteFromCart()"/>
      <!--BOTONERA  -->

      <div class="row mt-4">
        <div class="col-6 bg-white py-2">
          <transactionForm
            :type="type"
            :work-center="filterPlant"
            @validate="validatedForm = $event"
            @onChangeMainWarehouse="onChangeMainWarehouse($event)"
          >
            <template v-if="type === 'Salida'">
              <a-checkbox v-model="manifestForm">Requiere manifiesto de salida</a-checkbox>
              <transactionManifestForm  v-if="manifestForm" :work-center="filterPlant" @validate="validatedExitForm = $event"/>
            </template>
          </transactionForm>
        </div>
        <div class="col-6">
          <div class="row justify-content-end mb-4" v-if="validatedForm">
            <div class="col-6">
              <!-- Entrada -->
              <b-button
                pill
                block
                variant="primary"
                @click="InputSubmit"
                v-if="smcCart.length > 0"
              >
                <b-icon icon="archive"/> Guardar Entrada
              </b-button>
              <!-- Entrada -->
              <!-- Salida -->
              <template v-if="type === 'Salida'">
                <b-button v-if="titemCart.length > 0 && ((manifestForm && validatedExitForm) || (!manifestForm)) "
                          pill block variant="primary" @click="OutputSubmit">
                  <b-icon icon="archive"></b-icon> Guardar Salida
                </b-button>
              </template>
              <!-- Salida -->
              <!-- Traspaso -->
              <template v-if="type === 'Traspaso'">
                <b-button v-if="titemCart.length > 0"
                          pill block variant="primary" @click="TransferSubmit">
                  <b-icon icon="archive"></b-icon> Guardar Transferencia
                </b-button>
              </template>
              <!-- Traspaso -->
            </div>
          </div>

          <smcTable v-if="type ==='Entrada'" :work-center="filterPlant"/>
          <tdetailTitemsTable ref="tdetailTitemsTable" v-else :params="tdetailsTableParams" @formatTable="FormatTable($event)"/>
        </div>
      </div>
    </div>
    <my-spinner :load="loading"/>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import { fractalMixin } from '@/mixins/fractalMixin'
import smcTable from '../components/tables/smcTable'
import { mapActions, mapGetters, mapState } from 'vuex'
import transactionCart from '../components/transactionCart'
import tdetailTitemsTable from '../components/tables/tdetailTitemsTable'
import transactionForm from '../components/transactions/transactionForm'
import transactionManifestForm from '../components/transactions/transactionManifestForm'
export default {
  name: 'transaction',
  mixins: [fractalMixin],
  components: {
    transactionForm,
    transactionCart,
    smcTable,
    transactionManifestForm,
    tdetailTitemsTable,
  },
  computed: {
    ...mapState('warehouse', ['form', 'exitForm', 'titemCart', 'smcCart']),
    ...mapGetters('warehouse', ['titemCartIds']),
    tdetailsTableParams() {
      return this.paramsOfTdetailsTable
    },
  },
  data() {
    return {
      type: this.$route.params.type,
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Operaciones',
          aIcon: '',
          name: 'operations',
        },
        {
          breadcrumbName: 'Gestión de Almacenes',
          aIcon: '',
          name: 'warehouses',
        },
        {
          breadcrumbName: 'Transacciones',
          aIcon: '',
        },
      ],
      filterPlant: '',
      manifestForm: false,
      validatedForm: false,
      validatedExitForm: false,
      loading: false, // spinner
      paramsOfTdetailsTable: {
        include: 'wpositions',
        'filter[outputsAndTransfers]': '',
      },
    }
  },
  methods: {
    ...mapActions('warehouse', ['RESET_FORMS', 'RESET_CARTS']),
    ChangeWorkCenter(newValue, oldValue) {
      // Todo afinar reinicio
      // this.RESET_CARTS()
      const auxThis = this
      auxThis.RESET_FORMS()
      // Modal.confirm({
      //   title: '¿Desea cambiar de planta?',
      //   content: 'Esta operación reiniciará la operacion de transacción',
      //   okText: 'Sí',
      //   okType: 'danger',
      //   cancelText: 'No',
      //   zIndex: 3000,
      //   onOk () {
      //     console.log('reset')
      //     auxThis.RESET_FORMS()
      //   },
      //   onCancel () {
      //     auxThis.filterPlant = oldValue
      //   },
      // })
    },
    InputSubmit() {
      this.loading = true
      this.AxiosPost('/storeInputTransaction', {
        form: this.form,
        cart: this.smcCart,
      }, () => {
        this.loading = false
        Modal.success({
          title: 'Transacción exitosa',
          content: (
            <div class="row my-2 text-center">
              <p class="my-4">Se ha realizado la operación exitosamente de click a continuación para administrar los residuos</p>
              <b-button
                pill block
                variant="primary"
                to="/operaciones/almacenes/ubicacciones"
              >
                <b-icon icon="archive"></b-icon> Administrar Ubicaciones
              </b-button>
            </div>
          ),
        })
        this.RESET_FORMS()
      }, () => { this.loading = false })
    },
    OutputSubmit() {
      this.loading = true
      const params = {
        form: this.form,
        cart: this.titemCart,
      }
      if (this.manifestForm) {
        params.exit_manifest = this.exitForm
      }
      this.AxiosPost('/storeOutputTransaction', params, () => {
        this.loading = false
        this.RESET_FORMS()
      }, () => { this.loading = false })
    },
    TransferSubmit() {
      this.loading = true
      this.AxiosPost('/storeTransferTransaction', {
        form: this.form,
        cart: this.titemCart,
      }, () => {
        this.loading = false
        Modal.success({
          title: 'Transacción exitosa',
          content: (
            <div class="row my-2 text-center">
              <p class="my-4">Se ha realizado la operación exitosamente de click a continuación para administrar los residuos</p>
              <b-button
                pill block
                variant="primary"
                to="/operaciones/almacenes/ubicacciones"
              >
                <b-icon icon="archive"></b-icon> Administrar Ubicaciones
              </b-button>
            </div>
          ),
        })
        this.RESET_FORMS()
      }, () => { this.loading = false })
    },
    FormatTable(table) {
      if (table.data.length > 0) {
        table.loading = true
        /*
          Limpiar resultados, es decir mostar lo que:
          -- 1.-NO esten en el estado de cart
        */
        table.data = table.data.filter((titem) => {
          // -----------------  1 -------------
          return !this.titemCartIds.includes(titem.code)
        })
      }
      table.loading = false
    },
    deleteFromCart() {
      if (this.type !== 'Entrada') {
        this.$refs.tdetailTitemsTable.Fetch()
      }
    },
    onChangeMainWarehouse(warehouseId) {
      if (this.type !== 'Entrada') {
        this.paramsOfTdetailsTable['filter[warehouses]'] = warehouseId
        this.$refs.tdetailTitemsTable.Fetch()
      }
    },
  },
  mounted() {
    this.RESET_FORMS()
  },
}
</script>
