<template>
  <!--FORMULARIO-->
  <a-form-model :model="auxForm" :rules="formRules" ref="exitManifestForm"
                layout="vertical" :wrapper-col="{ span: 24}">
    <!--SELECT -Tipo de Manejo -->
    <a-form-model-item label="Tipo de Manejo" ref="management_type_id" prop="management_type_id" >
      <a-select v-model="auxForm.management_type_id" :disabled="lock"
                placeholder="Seleccionar Tipo de Manejo"
                show-search :filter-option="filterOption">
        <a-select-option  v-for="option in managementTypes" :key="option.id" :value="option.id" >
          {{option.name}}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <!--SELECT -Tipo de Manejo -->
    <!--SELECT -Tipo de Formulación -->
    <a-form-model-item label="Tipo de Formulación" ref="formulation_type_id" prop="formulation_type_id" >
      <a-select v-model="auxForm.formulation_type_id" :disabled="lock"
                placeholder="Seleccionar Tipo de Formulación"
                show-search :filter-option="filterOption">
        <a-select-option  v-for="option in formulationTypes" :key="option.id" :value="option.id" >
          {{ option.name }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <!--SELECT -Tipo de Formulación -->
    <!--SELECT -Transporte de Salida -->
    <a-form-model-item label="Transporte de Salida" ref="truck_id" prop="truck_id" >
      <a-select v-model="auxForm.truck_id" :disabled="lock"
                placeholder="Seleccionar Transporte de Salida"
                show-search :filter-option="filterOption">
        <a-select-option  v-for="option in trucks" :key="option.id" :value="option.id" >
          {{ option.tag }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <!--SELECT -Transporte de Salida -->
    <!--DATEPICKER - Fecha y Hora de Transacción-->
    <a-form-model-item  label="Fecha y Hora de reciclaje" ref="dated_at" prop="dated_at" >
      <a-date-picker  v-model="auxForm.dated_at" allowClear showTime :disabled="lock"
                      style="width: 100%" placeholder="Fecha y Hora de reciclaje"
                      format="DD/MM/YYYY HH:mm" size="large" valueFormat="YYYY/MM/DD HH:mm"/>
    </a-form-model-item>
    <!--DATEPICKER - Fecha y Hora de Transacción -->
    <!--TEXT - No. Autorización SEMARNAT-->
    <a-form-model-item label="No. Autorización SEMARNAT" ref="semarnat"  prop="semarnat" >
        <a-input v-model.trim="auxForm.semarnat" :disabled="lock" placeholder="No. Autorización SEMARNAT" size="large"/>
    </a-form-model-item>
    <!--TEXT -No. Autorización SEMARNAT -->
    <!--TEXT - No. Autorización de destino final-->
    <a-form-model-item label="No. Autorización de destino final" ref="final_destiny"  prop="final_destiny" >
      <a-input v-model.trim="auxForm.final_destiny" :disabled="lock" placeholder="No. Autorización de destino final" size="large"/>
    </a-form-model-item>
    <!--TEXT -No. Autorización de destino final -->
    <!--TEXT - Razón social de destino final-->
    <a-form-model-item label="Razón social de destino final" ref="social_reason"  prop="social_reason" >
      <a-input v-model.trim="auxForm.social_reason" :disabled="lock" placeholder="Razón social de destino final" size="large"/>
    </a-form-model-item>
    <!--TEXT -Razón social de destino final -->

    <!--Botones -->
    <a-row justify="end" type="flex">
      <b-button  variant="primary" @click="Submit" pill v-if="!lock">
        <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
          <b-icon icon="lock-fill"/> Guardar
        </div>
        <div class="d-md-none" v-b-tooltip.hover title="Guardar">
          <b-icon icon="lock-fill"/>
        </div>
      </b-button>
      <b-button  variant="warning" @click="Unlock" pill v-if="lock">
        <div class="d-none d-md-block" v-b-tooltip.hover title="Editar">
          <b-icon icon="unlock-fill"/> Editar
        </div>
        <div class="d-md-none" v-b-tooltip.hover title="Editar">
          <b-icon icon="unlock-fill"/>
        </div>
      </b-button>
    </a-row>
    <!--Botones -->

  </a-form-model>
  <!--FORMULARIO-->
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'transactionManifestForm',
  mixins: [fractalMixin],
  props: {
    workCenter: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapState('warehouse', ['exitForm']),
    auxForm: {
      get() {
        return this.exitForm
      },
      set(value) {
        this.UPDATE_EXIT_FORM(value)
      },
    },
  },
  watch: {
    async workCenter(newValue) {
      await this.Combos()
    },
  },
  data() {
    return {
      formRules: {
        management_type_id: [{ required: true, message: 'El campo Tipo de Manejo es obligatorio.', trigger: 'blur' }],
        formulation_type_id: [{ required: true, message: 'El campo Tipo de Formulación es obligatorio.', trigger: 'blur' }],
        truck_id: [{ required: true, message: 'El campo Transporte de Salida es obligatorio.', trigger: 'blur' }],
        dated_at: [{ required: true, message: 'El campo Fecha de reciclaje es obligatorio.', trigger: 'change' }],
      },
      managementTypes: [],
      formulationTypes: [],
      trucks: [],
      lock: false,
    }
  },
  methods: {
    ...mapActions('warehouse', ['UPDATE_EXIT_FORM']),
    async Combos () {
      this.trucks = await this.GetResource('/trucks', {
        sortBy: 'tag',
      })
      this.managementTypes = await this.GetResource('/managementTypes', {
        sortBy: 'name',
      })
      this.formulationTypes = await this.GetResource('/formulationTypes', {
        sortBy: 'name',
      })
    },
    Submit() {
      this.$refs.exitManifestForm.validate(async(valid) => {
        if (valid) {
          this.$emit('validate', true)
          this.lock = true
        } else return false
      })
    },
    Unlock() {
      this.$emit('validate', false)
      this.lock = false
    },
  },
  async mounted() {
    await this.Combos()
  },
}
</script>

<style scoped>

</style>
