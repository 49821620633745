<template>
  <div>
    <!--TABLA-->
    <a-table
      :scroll="{x:500}"
      :columns="tableObject.columns"
      :data-source="tableObject.data"
      :pagination="tableObject.pagination"
      :loading="tableObject.loading"
      @change="Fetch"
      :row-key="record => record.id" bordered>
      <div slot="details" slot-scope="record">
        <a-descriptions bordered size="small"
                        :column="{ sm: 1, md:2}">
          <a-descriptions-item label="Cliente">
            <strong>{{record.clientContracts.clients.sap_code}} - {{record.clientContracts.clients.name}}</strong>
          </a-descriptions-item>>
          <a-descriptions-item label="Contrato de SAP">
            <strong>{{record.clientContracts.contract_code}} </strong>
          </a-descriptions-item>>
          <a-descriptions-item label="Residuo de SAP">
            <strong>{{record.sapMaterials.name}}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Residuo">
            <strong>{{record.materials.name}}  </strong> {{record.materials.code}}
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <div slot="action" slot-scope="data">
        <b-button variant="success" pill @click="OpenModal(data)">
          <b-icon icon="hand-index"/>
        </b-button>
      </div>
    </a-table>
    <!--TABLA-->
    <!-- MODAL -->
    <b-modal title="Detalle de Entrada" ref="inputModal" size="lg" @hide="CloseModal" >
      <b-container fluid>
        <!--FORMULARIO-->
        <a-form-model :model="form" :rules="ruleForm" ref="inputForm"
            layout="vertical" :wrapper-col="{ span: 24}">
          <a-row align="middle" justify="center">
            <a-col :sm="{ span: 24 }" :md="{ span:8}">
              <!--NUMBER INPUT - Cantidad de contenedores-->
              <a-form-model-item label="Cantidad de contenedores" ref="container_quantity"  prop="container_quantity" >
                <a-input-number v-model.number="form.container_quantity" style="width: 100%"
                                :min="1" :step="1" size="large"/>
              </a-form-model-item>
              <!--NUMBER INPUT -Cantidad de contenedores -->
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span:16}">
              <!--SELECT -Tipo de Contenedor -->
              <a-form-model-item label="Tipo de Contenedor" ref="containerTypes" prop="containerTypes" >
                <a-select v-model="form.containerTypes"
                          placeholder="Seleccionar Tipo de Contenedor"
                          show-search :filter-option="filterOption">
                  <a-select-option  v-for="option in containerTypes" :key="option.id" :value="JSON.stringify(option)" >
                    {{option.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <!--SELECT -Tipo de Contenedor -->
            </a-col>
          </a-row>
          <a-row align="middle" justify="center">
            <a-col :sm="{ span: 24 }" :md="{ span:8}">
              <!--NUMBER INPUT - Cantidad real-->
              <a-form-model-item label="Cantidad real" ref="real_quantity"  prop="real_quantity" >
                <a-input-number v-model.number="form.real_quantity" style="width: 100%"
                                :min="0.0000001" :step="0.1" size="large"/>
              </a-form-model-item>
              <!--NUMBER INPUT -Cantidad de contenedores -->
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span:16}">
              <!--SELECT -Unidad de Medida -->
              <a-form-model-item label="Unidad de Medida" ref="ums" prop="ums" >
                  <a-select v-model="form.ums"
                              placeholder="Seleccionar Unidad de Medida"
                              show-search :filter-option="filterOption">
                      <a-select-option  v-for="option in ums" :key="option.id" :value="JSON.stringify(option)" >
                        {{option.name}}
                      </a-select-option>
                  </a-select>
              </a-form-model-item>
              <!--SELECT -Unidad de Medida -->
            </a-col>
          </a-row>
          <!--TEXT-AREA - Notas Especiales-->
          <a-form-model-item label="Notas Especiales" ref="comments"  prop="comments" >
              <a-textarea v-model.trim="form.comments" :auto-size="{ minRows: 2, maxRows: 7 }"/>
          </a-form-model-item>
          <!--TEXT-AREA -Notas Especiales -->
        </a-form-model>
        <a-row  type="flex" align="middle" justify="end">
          <b-button  variant="primary" @click="Submit" pill>
              <div class="d-none d-md-block" v-b-tooltip.hover title="Agregar">
                  <b-icon icon="cart-plus"/> Agregar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Agregar">
                  <b-icon icon="cart-plus"/>
              </div>
          </b-button>
        </a-row>
        <!--FORMULARIO-->
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- MODAL -->
  </div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import { mapActions } from 'vuex'
export default {
  name: 'smcTable',
  props: {
    workCenter: {
      required: true,
      type: String,
    },
  },
  mixins: [fractalMixin],
  data() {
    return {
      tableObject: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Detalle del Residuo',
            key: 'detail',
            class: 'small text-center',
            scopedSlots: {
              customRender: 'details',
            },
            width: '70%',
          },
          {
            title: 'Acción',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
            width: '15%',
          },
        ],
      },
      form: {
        sapMaterialContracts: undefined,
        containerTypes: undefined,
        ums: undefined,
        container_quantity: 1,
        real_quantity: 1,
        comments: '',
      },
      ruleForm: {
        sapMaterialContracts: [{ required: true, message: 'El campo Residuo es obligatorio.', trigger: 'blur' }],
        containerTypes: [{ required: true, message: 'El campo Tipo de Contenedor es obligatorio.', trigger: 'blur' }],
        ums: [{ required: true, message: 'El campo Unidad de Medida es obligatorio.', trigger: 'blur' }],
        container_quantity: [{ required: true, message: 'El campo Cantidad de Contenedores es obligatorio.', trigger: 'blur' },
          { type: 'integer', message: 'El campo Cantidad de Contenedores no puede ser decimal.', trigger: 'blur' },
          // { min: 0, max: 1000000, message: 'El campo Cantidad de Contenedores debe ser mayor a 0.', trigger: 'blur' }
        ],
        real_quantity: [{ required: true, message: 'El campo Cantidad Real es obligatorio.', trigger: 'blur' },
          // { min: 0.0000001, message: 'El campo Cantidad Real debe ser mayor a 0.', trigger: 'blur' }
        ],
      },
      containerTypes: [],
      ums: [],
    }
  },
  watch: {
    workCenter(newValue) {
      this.Fetch()
    },
  },
  methods: {
    ...mapActions('warehouse', ['STORE_SMC']),
    async Fetch(pageEvent = {}) {
      await this.GetTableResource(pageEvent, 'sapMaterialContracts', this.tableObject, {
        include: 'materials,clientContracts.clients,sapMaterials,ums',
        'filter[plants]': this.workCenter,
      })
    },
    async Catalogs() {
      this.ums = await this.GetResource('/ums', {
        sort: 'name',
      })
      this.containerTypes = await this.GetResource('/containerTypes', {
        sort: 'name',
      })
    },
    OpenModal(data) {
      this.$refs.inputModal.show()
      this.form.sapMaterialContracts = { id: data.id, materialName: data.materials.name }
    },
    CloseModal() {
      this.$refs.inputModal.hide()
      this.ResetForm()
    },
    Submit() {
      this.$refs.inputForm.validate(async(valid) => {
        if (valid) {
          const form = this.form
          form.containerTypes = JSON.parse(form.containerTypes)
          form.ums = JSON.parse(form.ums)
          this.STORE_SMC(form)
          this.ResetForm()
          this.CloseModal()
        } else return false
      })
    },
    ResetForm() {
      this.form = {
        sapMaterialContracts: undefined,
        containerTypes: undefined,
        ums: undefined,
        container_quantity: 1,
        real_quantity: 1,
        comments: '',
      }
    },
  },
  async mounted() {
    if (this.workCenter) {
      await this.Fetch()
    }
    await this.Catalogs()
  },
}
</script>

<style scoped>

</style>
